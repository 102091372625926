import { graphql } from "gatsby";
import * as React from "react";
import { Columns } from "react-bulma-components";

import { ContainerMobile, ImageGallery, Layout, PhotoColumn, Title } from "../components/index";

export const query = graphql`
    query QueryPhotos {
        photos: allPhotosJson {
            nodes {
                id
                alt
                imageLarge {
                    ...fragmentLargeLightboxImage
                }
                imagePreview {
                    ...blurredPlaceholder
                }
            }
        }
    }
`;

const Photos = ({ data: { photos }, ...props }) => {
    return (
        <Layout {...props}>
            <Title>Photos</Title>

            <ContainerMobile>
                <Columns multiline={true} textAlign="center">
                    <ImageGallery
                        uniqueGalleryName="Photos"
                        photosLarge={photos.nodes.map((photo) => { 
                            return {
                                url: photo.imageLarge.childImageSharp.fluid.src,
                                alt: photo.alt
                            };
                        })}
                    >
                        {photos.nodes.map(({ id, alt, imagePreview }, i) => {
                            return (
                                <PhotoColumn
                                    imagePreview={imagePreview}
                                    alt={alt}
                                    index={i}
                                    key={id}
                                />
                            );
                        })}
                    </ImageGallery>
                </Columns>
            </ContainerMobile>
        </Layout>
    );
};

export default Photos;